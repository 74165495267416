export const sportArt = [
    {id: 'American Football', name: 'American Football'},
    {id: 'Baseball', name: 'Baseball'},
    {id: 'Basketball', name: 'Basketball'},
    {id: 'Beachvolleyball', name: 'Beachvolleyball'},
    {id: 'Biathlon', name: 'Biathlon'},
    {id: 'Bob', name: 'Bob'},
    {id: 'Boxen', name: 'Boxen'},
    {id: 'Darts', name: 'Darts'},
    {id: 'Eishockey', name: 'Eishockey'},
    {id: 'eSports', name: 'eSports'},
    {id: 'Exatlon', name: 'Exatlon'},
    {id: 'Fußball', name: 'Fußball'},
    {id: 'Futsal', name: 'Futsal'},
    {id: 'Golf', name: 'Golf'},
    {id: 'Handball', name: 'Handball'},
    {id: 'Hockey', name: 'Hockey'},
    {id: 'Leichtathletik', name: 'Leichtathletik'},
    {id: 'Motorsport', name: 'Motorsport'},
    {id: 'Nordische Kombination', name: 'Nordische Kombination'},
    {id: 'Olympia', name: 'Olympia'},
    {id: 'Poker', name: 'Poker'},
    {id: 'Radsport', name: 'Radsport'},
    {id: 'Rugby', name: 'Rugby'},
    {id: 'Schwimmen', name: 'Schwimmen'},
    {id: 'Segeln', name: 'Segeln'},
    {id: 'Skeleton', name: 'Skeleton'},
    {id: 'Ski Alpin', name: 'Ski Alpin'},
    {id: 'Skispringen', name: 'Skispringen'},
    {id: 'Special Olympics', name: 'Special Olympics'},
    {id: 'Snowboard', name: 'Snowboard'},
    {id: 'Tennis', name: 'Tennis'},
    {id: 'Tischtennis', name: 'Tischtennis'},
    {id: 'Triathlon', name: 'Triathlon'},
    {id: 'Volleyball', name: 'Volleyball'},
    {id: 'Wasserball', name: 'Wasserball'},
    {id: 'Wintersport', name: 'Wintersport'},
    {id: 'Wrestling', name: 'Wrestling'},
    {id: 'Sonstige', name: 'Sonstige'},
];

export const competition = [
    {id: '24-H-Nürburgring', name: '24-H-Nürburgring'},
    {id: '2. Bundesliga', name: '2. Bundesliga'},
    {id: '3. Liga', name: '3. Liga'},
    {id: 'ADAC Formel 4', name: 'ADAC Formel 4'},
    {id: 'ADAC GT Masters', name: 'ADAC GT Masters'},
    {id: 'ADAC TCR Germany', name: 'ADAC TCR Germany'},
    {id: 'AEW', name: 'AEW'},
    {id: 'Afrika-Cup', name: 'Afrika-Cup'},
    {id: 'ATP', name: 'ATP'},
    {id: 'Audi Sport TT Cup', name: 'Audi Sport TT Cup'},
    {id: 'Australian Open', name: 'Australian Open'},
    {id: 'Bathurst', name: 'Bathurst'},
    {id: 'BBL', name: 'BBL'},
    {id: 'BBL-Pokal', name: 'BBL-Pokal'},
    {id: 'Biathlon Weltcup', name: 'Biathlon Weltcup'},
    {id: 'Biathlon-WM', name: 'Biathlon-WM'},
    {id: 'Big Air', name: 'Big Air'},
    {id: 'BMW International Open', name: 'BMW International Open'},
    {id: 'Bundesliga', name: 'Bundesliga'},
    {id: 'Bundesliga Frauen', name: 'Bundesliga Frauen'},
    {id: 'Champions League', name: 'Champions League'},
    {id: 'CHL', name: 'CHL'},
    {id: 'Confed Cup', name: 'Confed Cup'},
    {id: 'Coupe de France', name: 'Coupe de France'},
    {id: 'Darts-WM', name: 'Darts-WM'},
    {id: 'Davis Cup', name: 'Davis Cup'},
    {id: 'Daytona', name: 'Daytona'},
    {id: 'DEL', name: 'DEL'},
    {id: 'DFB-Pokal', name: 'DFB-Pokal'},
    {id: 'DFB-Team', name: 'DFB-Team'},
    {id: 'Dota 2', name: 'Dota 2'},
    {id: 'DTM', name: 'DTM'},
    {id: 'EM', name: 'EM'},
    {id: 'Eishockey', name: 'Eishockey'},
    {id: 'Eishockey WM', name: 'Eishockey WM'},
    {id: 'Eurocup', name: 'Eurocup'},
    {id: 'EuroLeague', name: 'EuroLeague'},
    {id: 'Fed Cup', name: 'Fed Cup'},
    {id: 'FIA WEC', name: 'FIA WEC'},
    {id: 'FIFA Frauen WM', name: 'FIFA Frauen WM'},
    {id: 'FIFA WM', name: 'FIFA WM'},
    {id: 'Formel 1', name: 'Formel 1'},
    {id: 'Formel E', name: 'Formel E'},
    {id: 'Frauenfußball', name: 'Frauenfußball'},
    {id: 'French Open', name: 'French Open'},
    {id: 'Giro d\'Italia', name: 'Giro d\'Italia'},
    {id: 'Halfpipe', name: 'Halfpipe'},
    {id: 'Handball Bundesliga', name: 'Handball Bundesliga'},
    {id: 'Handball EM', name: 'Handball EM'},
    {id: 'Handball Frauen', name: 'Handball Frauen'},
    {id: 'Handball Nationalmannschaft', name: 'Handball Nationalmannschaft'},
    {id: 'Hearthstone', name: 'Hearthstone'},
    {id: 'Heroes of the Storm', name: 'Heroes of the Storm'},
    {id: 'Hockey', name: 'Hockey'},
    {id: 'IMSA', name: 'IMSA'},
    {id: 'IndyCar', name: 'IndyCar'},
    {id: 'Klub-WM', name: 'Klub-WM'},
    {id: 'La Liga', name: 'La Liga'},
    {id: 'League of Legends', name: 'League of Legends'},
    {id: 'Leichtathletik', name: 'Leichtathletik'},
    {id: 'Le Mans', name: 'Le Mans'},
    {id: 'Ligue 1', name: 'Ligue 1'},
    {id: 'MLB', name: 'MLB'},
    {id: 'MLS', name: 'MLS'},
    {id: 'Monster Jam', name: 'Monster Jam'},
    {id: 'MotoGP', name: 'MotoGP'},
    {id: 'NASCAR', name: 'NASCAR'},
    {id: 'NBA', name: 'NBA'},
    {id: 'NFL', name: 'NFL'},
    {id: 'NHL', name: 'NHL'},
    {id: 'Nordische Kombination Weltcup', name: 'Nordische Kombination Weltcup'},
    {id: 'Olympia', name: 'Olympia'},
    {id: 'PDC Next Gen', name: 'PDC Next Gen'},
    {id: 'Porsche Carrera Cup', name: 'Porsche Carrera Cup'},
    {id: 'Porsche Supercup', name: 'Porsche Supercup'},
    {id: 'Premier League of Darts', name: 'Premier League of Darts'},
    {id: 'Premier League', name: 'Premier League'},
    {id: 'Radsport', name: 'Radsport'},
    {id: 'Regionalliga', name: 'Regionalliga'},
    {id: 'Serie A', name: 'Serie A'},
    {id: 'Ski Alpin Weltcup', name: 'Ski Alpin Weltcup'},
    {id: 'Skiflug-WM', name: 'Skiflug-WM'},
    {id: 'Skispringen Weltcup', name: 'Skispringen Weltcup'},
    {id: 'Solheim Cup', name: 'Solheim Cup'},
    {id: 'StarCraft II', name: 'StarCraft II'},
    {id: 'Tour de France', name: 'Tour de France'},
    {id: 'Transfermarkt', name: 'Transfermarkt'},
    {id: 'U21', name: 'U21'},
    {id: 'UEFA Europa League', name: 'UEFA Europa League'},
    {id: 'US Open', name: 'US Open'},
    {id: 'Vierschanzentournee', name: 'Vierschanzentournee'},
    {id: 'VLN', name: 'VLN'},
    {id: 'Volleyball Bundesliga', name: 'Volleyball Bundesliga'},
    {id: 'WEC', name: 'WEC'},
    {id: 'Wimbledon', name: 'Wimbledon'},
    {id: 'World Cup of Hockey', name: 'World Cup of Hockey'},
    {id: 'World Matchplay', name: 'World Matchplay'},
    {id: 'WRC', name: 'WRC'},
    {id: 'Wrestling', name: 'Wrestling'},
    {id: 'WTA', name: 'WTA'},
    {id: 'WWE', name: 'WWE'},
    {id: 'Youth League', name: 'Youth League'},
];

export const source = [
    {id: 'DAZN', name: 'DAZN'},
    {id: 'DFL', name: 'DFL'},
    {id: 'Dyn', name: 'Dyn'},
    {id: 'FCB.tv', name: 'FCB.tv'},
    {id: 'Omnisport', name: 'Omnisport'},
    {id: 'Sky', name: 'Sky'},
    {id: 'SPORT1', name: 'SPORT1'},
    {id: 'Magenta', name: 'Magenta'},
    {id: 'SID', name: 'SID'},
    {id: 'Porsche', name: 'Porsche'},
    {id: 'WWE-Feed', name: 'WWE-Feed'},
    {id: 'ARD', name: 'ARD'},
    {id: 'ZDF', name: 'ZDF'},
    {id: 'Sonstige', name: 'Sonstige'},
];

/**
 * If adding new shows here, check if program IDs can be set here:
 * /Sources/App/Services/Agf/showname_to_programs.json
 */
export const programme = [
    {id: '2 nach 10', name: '2 nach 10'},
    {id: '3. Liga Pur', name: '3. Liga Pur'},
    {id: 'American Football - XFL', name: 'American Football - XFL'},
    {id: 'Basketball Live - Die BBL', name: 'Basketball Live - Die BBL'},
    {id: 'Bundesliga pur', name: 'Bundesliga pur'},
    {id: 'Catch Tag', name: 'Catch Tag'},
    {id: 'Darts - European Championship', name: 'Darts - European Championship'},
    {id: 'Darts - Grand Slam of Darts', name: 'Darts - Grand Slam of Darts'},
    {id: 'Darts - PDC Europe', name: 'Darts - PDC Europe'},
    {id: 'Darts - Premier League Darts', name: 'Darts - Premier League Darts'},
    {id: 'Darts - UK Open', name: 'Darts - UK Open'},
    {id: 'Darts - WM', name: 'Darts - WM'},
    {id: 'Darts - World Series of Darts', name: 'Darts - World Series of Darts'},
    {id: 'DFB Pokal', name: 'DFB Pokal'},
    {id: 'Die PS Profis', name: 'Die PS Profis'},
    {id: 'Doppelpass', name: 'Doppelpass'},
    {id: 'Eishockey Live - Champions Hockey League', name: 'Eishockey Live - Champions Hockey League'},
    {id: 'Eishockey WM', name: 'Eishockey WM'},
    {id: 'eSports - Virtual Bundesliga', name: 'eSports - Virtual Bundesliga'},
    {id: 'Exatlon', name: 'Exatlon'},
    {id: 'Fantalk', name: 'Fantalk'},
    {id: 'FLYERALARM FRAUEN-BUNDESLIGA', name: 'FLYERALARM FRAUEN-BUNDESLIGA'},
    {id: 'Fußball - 2. Liga Live', name: 'Fußball - 2. Liga Live'},
    {id: 'Fußball für Besserwisser', name: 'Fußball für Besserwisser'},
    {id: 'Handball DHB-Pokal', name: 'Handball DHB-Pokal'},
    {id: 'Inside eSports', name: 'Inside eSports'},
    {id: 'Monster Jam', name: 'Monster Jam'},
    {id: 'Motorsport - FIA World Rally Championship', name: 'Motorsport - FIA World Rally Championship'},
    {id: 'Poker - WSOP', name: 'Poker - WSOP'},
    {id: 'SKI & BERGE', name: 'SKI & BERGE'},
    {id: 'SPORT1 Bundesliga Classics', name: 'SPORT1 Bundesliga Classics'},
    {id: 'SPORT1 Generation Fußball', name: 'SPORT1 Generation Fußball'},
    {id: 'SPORT1 History', name: 'SPORT1 History'},
    {id: 'SPORT1 News', name: 'SPORT1 News'},
    {id: 'Story of the Week', name: 'Story of the Week'},
    {id: 'Talent Watch', name: 'Talent Watch'},
    {id: 'Transfermarkt-Show', name: 'Transfermarkt-Show'},
    {id: 'Volleyball Live', name: 'Volleyball Live'},
    {id: 'Was macht eigentlich', name: 'Was macht eigentlich...?'},
    {id: 'Wie gut war eigentlich', name: 'Wie gut war eigentlich...?'}
];

export const episode = [
];

export const countries = [
    { 'code': 'DE', 'nameEn': 'Germany'},
    { 'code': 'AT', 'nameEn': 'Austria'},
    { 'code': 'CH', 'nameEn': 'Switzerland'},
    { 'code': 'AF', 'nameEn': 'Afghanistan'},
    { 'code': 'EG', 'nameEn': 'Egypt'},
    { 'code': 'AX', 'nameEn': 'Åland Islands'},
    { 'code': 'AL', 'nameEn': 'Albania'},
    { 'code': 'DZ', 'nameEn': 'Algeria'},
    { 'code': 'AS', 'nameEn': 'American Samoa'},
    { 'code': 'AD', 'nameEn': 'Andorra'},
    { 'code': 'AO', 'nameEn': 'Angola'},
    { 'code': 'AI', 'nameEn': 'Anguilla'},
    { 'code': 'AQ', 'nameEn': 'Antarctica'},
    { 'code': 'AG', 'nameEn': 'Antigua and Barbuda'},
    { 'code': 'GQ', 'nameEn': 'Equatorial Guinea'},
    { 'code': 'AR', 'nameEn': 'Argentina'},
    { 'code': 'AM', 'nameEn': 'Armenia'},
    { 'code': 'AW', 'nameEn': 'Aruba'},
    { 'code': 'AZ', 'nameEn': 'Azerbaijan'},
    { 'code': 'ET', 'nameEn': 'Ethiopia'},
    { 'code': 'AU', 'nameEn': 'Australia'},
    { 'code': 'BS', 'nameEn': 'Bahamas'},
    { 'code': 'BH', 'nameEn': 'Bahrain'},
    { 'code': 'BD', 'nameEn': 'Bangladesh'},
    { 'code': 'BB', 'nameEn': 'Barbados'},
    { 'code': 'BE', 'nameEn': 'Belgium'},
    { 'code': 'BZ', 'nameEn': 'Belize'},
    { 'code': 'BJ', 'nameEn': 'Benin'},
    { 'code': 'BM', 'nameEn': 'Bermuda'},
    { 'code': 'BT', 'nameEn': 'Bhutan'},
    { 'code': 'BO', 'nameEn': 'Bolivia'},
    { 'code': 'BQ', 'nameEn': 'Bonaire'},
    { 'code': 'BA', 'nameEn': 'Bosnia and Herzegovina'},
    { 'code': 'BW', 'nameEn': 'Botswana'},
    { 'code': 'BV', 'nameEn': 'Bouvet Island'},
    { 'code': 'BR', 'nameEn': 'Brazil'},
    { 'code': 'VG', 'nameEn': 'British Virgin Islands'},
    { 'code': 'IO', 'nameEn': 'British Indian Ocean Territory'},
    { 'code': 'BN', 'nameEn': 'Brunei Darussalam'},
    { 'code': 'BG', 'nameEn': 'Bulgaria'},
    { 'code': 'BF', 'nameEn': 'Burkina Faso'},
    { 'code': 'BI', 'nameEn': 'Burundi'},
    { 'code': 'KY', 'nameEn': 'Cayman Islands'},
    { 'code': 'CL', 'nameEn': 'Chile'},
    { 'code': 'CX', 'nameEn': 'Christmas Island'},
    { 'code': 'CC', 'nameEn': 'Cocos (Keeling) Islands'},
    { 'code': 'CK', 'nameEn': 'Cook Islands'},
    { 'code': 'CR', 'nameEn': 'Costa Rica'},
    { 'code': 'CW', 'nameEn': 'Curacao'},
    { 'code': 'CD', 'nameEn': 'Democratic Republic of the Congo'},
    { 'code': 'DM', 'nameEn': 'Dominica'},
    { 'code': 'DO', 'nameEn': 'Dominican Republic'},
    { 'code': 'DJ', 'nameEn': 'Djibouti'},
    { 'code': 'DK', 'nameEn': 'Denmark'},
    { 'code': 'EC', 'nameEn': 'Ecuador'},
    { 'code': 'SV', 'nameEn': 'El Salvador'},
    { 'code': 'CI', 'nameEn': 'Cote d\'Ivoire'},
    { 'code': 'ER', 'nameEn': 'Eritrea'},
    { 'code': 'EE', 'nameEn': 'Estonia'},
    { 'code': 'FK', 'nameEn': 'Falkland Islands (Malvinas)'},
    { 'code': 'FJ', 'nameEn': 'Fiji'},
    { 'code': 'FI', 'nameEn': 'Finland'},
    { 'code': 'FR', 'nameEn': 'France'},
    { 'code': 'GF', 'nameEn': 'French Guiana'},
    { 'code': 'PF', 'nameEn': 'French Polynesia'},
    { 'code': 'TF', 'nameEn': 'French Southern Territories'},
    { 'code': 'FO', 'nameEn': 'Faroe Islands'},
    { 'code': 'GA', 'nameEn': 'Gabon'},
    { 'code': 'GM', 'nameEn': 'Gambia'},
    { 'code': 'GE', 'nameEn': 'Georgia'},
    { 'code': 'GH', 'nameEn': 'Ghana'},
    { 'code': 'GI', 'nameEn': 'Gibraltar'},
    { 'code': 'GD', 'nameEn': 'Grenada'},
    { 'code': 'GR', 'nameEn': 'Greece'},
    { 'code': 'GL', 'nameEn': 'Greenland'},
    { 'code': 'GP', 'nameEn': 'Guadeloupe'},
    { 'code': 'GU', 'nameEn': 'Guam'},
    { 'code': 'GT', 'nameEn': 'Guatemala'},
    { 'code': 'GG', 'nameEn': 'Guernsey'},
    { 'code': 'GN', 'nameEn': 'Guinea'},
    { 'code': 'GW', 'nameEn': 'Guinea-Bissau'},
    { 'code': 'GY', 'nameEn': 'Guyana'},
    { 'code': 'HT', 'nameEn': 'Haiti'},
    { 'code': 'HM', 'nameEn': 'Heard Island and McDonald Islands'},
    { 'code': 'HN', 'nameEn': 'Honduras'},
    { 'code': 'HK', 'nameEn': 'Hong Kong'},
    { 'code': 'IN', 'nameEn': 'India'},
    { 'code': 'ID', 'nameEn': 'Indonesia'},
    { 'code': 'IQ', 'nameEn': 'Iraq'},
    { 'code': 'IR', 'nameEn': 'Iran, Islamic Republic of'},
    { 'code': 'IE', 'nameEn': 'Ireland'},
    { 'code': 'IS', 'nameEn': 'Iceland'},
    { 'code': 'IM', 'nameEn': 'Isle of Man'},
    { 'code': 'IL', 'nameEn': 'Israel'},
    { 'code': 'IT', 'nameEn': 'Italy'},
    { 'code': 'JM', 'nameEn': 'Jamaica'},
    { 'code': 'JP', 'nameEn': 'Japan'},
    { 'code': 'YE', 'nameEn': 'Yemen'},
    { 'code': 'JE', 'nameEn': 'Jersey'},
    { 'code': 'JO', 'nameEn': 'Jordan'},
    { 'code': 'KH', 'nameEn': 'Cambodia'},
    { 'code': 'CM', 'nameEn': 'Cameroon'},
    { 'code': 'CA', 'nameEn': 'Canada'},
    { 'code': 'CV', 'nameEn': 'Cape Verde'},
    { 'code': 'KZ', 'nameEn': 'Kazakhstan'},
    { 'code': 'QA', 'nameEn': 'Qatar'},
    { 'code': 'KE', 'nameEn': 'Kenya'},
    { 'code': 'KG', 'nameEn': 'Kyrgyzstan'},
    { 'code': 'KI', 'nameEn': 'Kiribati'},
    { 'code': 'CO', 'nameEn': 'Colombia'},
    { 'code': 'KM', 'nameEn': 'Comoros'},
    { 'code': 'XK', 'nameEn': 'Kosovo'},
    { 'code': 'HR', 'nameEn': 'Croatia'},
    { 'code': 'CU', 'nameEn': 'Cuba'},
    { 'code': 'KW', 'nameEn': 'Kuwait'},
    { 'code': 'LA', 'nameEn': 'Lao People\'s Democratic Republic'},
    { 'code': 'LS', 'nameEn': 'Lesotho'},
    { 'code': 'LV', 'nameEn': 'Latvia'},
    { 'code': 'LB', 'nameEn': 'Lebanon'},
    { 'code': 'LR', 'nameEn': 'Liberia'},
    { 'code': 'LY', 'nameEn': 'Libya'},
    { 'code': 'LI', 'nameEn': 'Liechtenstein'},
    { 'code': 'LT', 'nameEn': 'Lithuania'},
    { 'code': 'LU', 'nameEn': 'Luxembourg'},
    { 'code': 'MO', 'nameEn': 'Macao'},
    { 'code': 'MG', 'nameEn': 'Madagascar'},
    { 'code': 'MW', 'nameEn': 'Malawi'},
    { 'code': 'MY', 'nameEn': 'Malaysia'},
    { 'code': 'MV', 'nameEn': 'Maldives'},
    { 'code': 'ML', 'nameEn': 'Mali'},
    { 'code': 'MA', 'nameEn': 'Morocco'},
    { 'code': 'MH', 'nameEn': 'Marshall Islands'},
    { 'code': 'MQ', 'nameEn': 'Martinique'},
    { 'code': 'MR', 'nameEn': 'Mauritania'},
    { 'code': 'MU', 'nameEn': 'Mauritius'},
    { 'code': 'YT', 'nameEn': 'Mayotte'},
    { 'code': 'MX', 'nameEn': 'Mexico'},
    { 'code': 'FM', 'nameEn': 'Micronesia, Federated States of'},
    { 'code': 'MC', 'nameEn': 'Monaco'},
    { 'code': 'MN', 'nameEn': 'Mongolia'},
    { 'code': 'ME', 'nameEn': 'Montenegro'},
    { 'code': 'MS', 'nameEn': 'Montserrat'},
    { 'code': 'MZ', 'nameEn': 'Mozambique'},
    { 'code': 'MM', 'nameEn': 'Myanmar'},
    { 'code': 'NA', 'nameEn': 'Namibia'},
    { 'code': 'NR', 'nameEn': 'Nauru'},
    { 'code': 'NP', 'nameEn': 'Nepal'},
    { 'code': 'NC', 'nameEn': 'New Caledonia'},
    { 'code': 'NZ', 'nameEn': 'New Zealand'},
    { 'code': 'NI', 'nameEn': 'Nicaragua'},
    { 'code': 'NL', 'nameEn': 'Netherlands'},
    { 'code': 'AN', 'nameEn': 'Netherlands Antilles'},
    { 'code': 'NE', 'nameEn': 'Niger'},
    { 'code': 'NG', 'nameEn': 'Nigeria'},
    { 'code': 'NU', 'nameEn': 'Niue'},
    { 'code': 'KP', 'nameEn': 'Korea, Democratic People\'s Republic of'},
    { 'code': 'MK', 'nameEn': 'Macedonia, the Former Yugoslav Republic of'},
    { 'code': 'NF', 'nameEn': 'Norfolk Island'},
    { 'code': 'MP', 'nameEn': 'Northern Mariana Islands'},
    { 'code': 'NO', 'nameEn': 'Norway'},
    { 'code': 'OM', 'nameEn': 'Oman'},
    { 'code': 'TL', 'nameEn': 'Timor-Leste'},
    { 'code': 'PK', 'nameEn': 'Pakistan'},
    { 'code': 'PW', 'nameEn': 'Palau'},
    { 'code': 'PS', 'nameEn': 'Palestine, State of'},
    { 'code': 'PA', 'nameEn': 'Panama'},
    { 'code': 'PG', 'nameEn': 'Papua New Guinea'},
    { 'code': 'PY', 'nameEn': 'Paraguay'},
    { 'code': 'PE', 'nameEn': 'Peru'},
    { 'code': 'PH', 'nameEn': 'Philippines'},
    { 'code': 'PN', 'nameEn': 'Pitcairn'},
    { 'code': 'PL', 'nameEn': 'Poland'},
    { 'code': 'PT', 'nameEn': 'Portugal'},
    { 'code': 'PR', 'nameEn': 'Puerto Rico'},
    { 'code': 'TW', 'nameEn': 'Taiwan'},
    { 'code': 'CG', 'nameEn': 'Congo'},
    { 'code': 'MD', 'nameEn': 'Moldova, Republic of'},
    { 'code': 'CY', 'nameEn': 'Cyprus'},
    { 'code': 'RE', 'nameEn': 'Reunion'},
    { 'code': 'RW', 'nameEn': 'Rwanda'},
    { 'code': 'RO', 'nameEn': 'Romania'},
    { 'code': 'RU', 'nameEn': 'Russian Federation'},
    { 'code': 'BL', 'nameEn': 'Saint Barthelemy'},
    { 'code': 'SH', 'nameEn': 'Saint Helena'},
    { 'code': 'MF', 'nameEn': 'Saint Martin (French part)'},
    { 'code': 'PM', 'nameEn': 'Saint Pierre and Miquelon'},
    { 'code': 'SB', 'nameEn': 'Solomon Islands'},
    { 'code': 'ZM', 'nameEn': 'Zambia'},
    { 'code': 'WS', 'nameEn': 'Samoa'},
    { 'code': 'SM', 'nameEn': 'San Marino'},
    { 'code': 'SA', 'nameEn': 'Saudi Arabia'},
    { 'code': 'SE', 'nameEn': 'Sweden'},
    { 'code': 'SN', 'nameEn': 'Senegal'},
    { 'code': 'RS', 'nameEn': 'Serbia'},
    { 'code': 'SC', 'nameEn': 'Seychelles'},
    { 'code': 'SL', 'nameEn': 'Sierra Leone'},
    { 'code': 'ZW', 'nameEn': 'Zimbabwe'},
    { 'code': 'SG', 'nameEn': 'Singapore'},
    { 'code': 'SX', 'nameEn': 'Sint Maarten (Dutch part)'},
    { 'code': 'SK', 'nameEn': 'Slovakia'},
    { 'code': 'SI', 'nameEn': 'Slovenia'},
    { 'code': 'SO', 'nameEn': 'Somalia'},
    { 'code': 'GS', 'nameEn': 'South Georgia and the South Sandwich Islands'},
    { 'code': 'MT', 'nameEn': 'Malta'},
    { 'code': 'ES', 'nameEn': 'Spain'},
    { 'code': 'LK', 'nameEn': 'Sri Lanka'},
    { 'code': 'KN', 'nameEn': 'Saint Kitts and Nevis'},
    { 'code': 'LC', 'nameEn': 'Saint Lucia'},
    { 'code': 'VC', 'nameEn': 'Saint Vincent and the Grenadines'},
    { 'code': 'SD', 'nameEn': 'Sudan'},
    { 'code': 'SR', 'nameEn': 'Suriname'},
    { 'code': 'SJ', 'nameEn': 'Svalbard and Jan Mayen'},
    { 'code': 'SZ', 'nameEn': 'Swaziland'},
    { 'code': 'SY', 'nameEn': 'Syrian Arab Republic'},
    { 'code': 'ST', 'nameEn': 'Sao Tome and Principe'},
    { 'code': 'ZA', 'nameEn': 'South Africa'},
    { 'code': 'KR', 'nameEn': 'Korea, Republic of'},
    { 'code': 'SS', 'nameEn': 'South Sudan'},
    { 'code': 'TJ', 'nameEn': 'Tajikistan'},
    { 'code': 'TZ', 'nameEn': 'United Republic of Tanzania'},
    { 'code': 'TH', 'nameEn': 'Thailand'},
    { 'code': 'TG', 'nameEn': 'Togo'},
    { 'code': 'TK', 'nameEn': 'Tokelau'},
    { 'code': 'TO', 'nameEn': 'Tonga'},
    { 'code': 'TT', 'nameEn': 'Trinidad and Tobago'},
    { 'code': 'TD', 'nameEn': 'Chad'},
    { 'code': 'CZ', 'nameEn': 'Czech Republic'},
    { 'code': 'TN', 'nameEn': 'Tunisia'},
    { 'code': 'TM', 'nameEn': 'Turkmenistan'},
    { 'code': 'TC', 'nameEn': 'Turks and Caicos Islands'},
    { 'code': 'TV', 'nameEn': 'Tuvalu'},
    { 'code': 'TR', 'nameEn': 'Turkey'},
    { 'code': 'VI', 'nameEn': 'US Virgin Islands'},
    { 'code': 'UG', 'nameEn': 'Uganda'},
    { 'code': 'UA', 'nameEn': 'Ukraine'},
    { 'code': 'HU', 'nameEn': 'Hungary'},
    { 'code': 'UM', 'nameEn': 'United States Minor Outlying Islands'},
    { 'code': 'UY', 'nameEn': 'Uruguay'},
    { 'code': 'UZ', 'nameEn': 'Uzbekistan'},
    { 'code': 'VU', 'nameEn': 'Vanuatu'},
    { 'code': 'VA', 'nameEn': 'Holy See (Vatican City State)'},
    { 'code': 'VE', 'nameEn': 'Venezuela'},
    { 'code': 'AE', 'nameEn': 'United Arab Emirates'},
    { 'code': 'US', 'nameEn': 'United States'},
    { 'code': 'GB', 'nameEn': 'United Kingdom'},
    { 'code': 'VN', 'nameEn': 'Viet Nam'},
    { 'code': 'CN', 'nameEn': 'China'},
    { 'code': 'WF', 'nameEn': 'Wallis and Futuna'},
    { 'code': 'BY', 'nameEn': 'Belarus'},
    { 'code': 'EH', 'nameEn': 'Western Sahara'},
    { 'code': 'CF', 'nameEn': 'Central African Republic'},
];

export const files = [
    {id: 11123, filename: 'video.mp4', format: 'jpeg', bitrate: 11100, dimensions: '1920x1080', asset_types: '', public_urls: 1},
    {id: 33123, filename: 'video3.mp4', format: 'mpeg4', bitrate: 32100, dimensions: '1280x720', asset_types: 'Mezzanine', public_urls: 1},
    {id: 33123, filename: 'video3.mp4', format: 'mxf', bitrate: 60100, dimensions: '1920x1080', asset_types: 'Mezzanine', public_urls: null},
];

export const content = [
    {id: 'highlights', name: 'Highlights'},
    {id: 'teaser', name: 'Vorbericht'},
];

export const ageRating = [
    { id: '6', name: 'ab 6' },
    { id: '12', name: 'ab 12' },
    { id: '16', name: 'ab 16' },
    { id: '18', name: 'ab 18' }
];